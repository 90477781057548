import React, { Fragment } from 'react';
import styles from './HighlightedProposal.module.scss';
import { IconPiggyBank, Text } from 'dss-ui-library';
import {
  FTTH_PROPOSALS,
  HIGHLIGHTED_PRETEXT_FTTH,
  HIGHLIGHTED_PROPOSALS,
} from '../../../../constants';
import { DisplayProposal } from '@ncs-frontend-monorepo/order';

interface HighlightedProposalsProps {
  displayProposals: DisplayProposal[];
}
export const HighlightedProposals: React.FC<HighlightedProposalsProps> = ({
  displayProposals,
}) => {
  const highlightedProposal = displayProposals?.filter((proposal) =>
    HIGHLIGHTED_PROPOSALS.includes(proposal.id),
  );

  const isPrefixText = highlightedProposal.some((proposal) =>
    FTTH_PROPOSALS.includes(proposal.id),
  );

  return (
    <>
      {highlightedProposal.length > 0 && (
        <div className={styles.proposalItem}>
          <IconPiggyBank className={styles.icon} scale="small" color="blue" />
          <Text appearance="t6_2" color="blue">
            {isPrefixText ? HIGHLIGHTED_PRETEXT_FTTH : 'Nur für dich: '}
            Du erhältst zusätzlich{' '}
            {highlightedProposal.map((proposal, ix) => (
              <Fragment key={`${ix}-${proposal.id}`}>
                {ix > 0 && ' und '}
                <span data-e2e={`proposal-${proposal.id}`}>
                  {proposal.discount.total} &euro; {proposal.displayName}
                </span>
              </Fragment>
            ))}
            .
          </Text>
        </div>
      )}
    </>
  );
};
