import { IconEdit, Link, Spacer, Text } from 'dss-ui-library';
import React from 'react';
import { FormFieldValues } from '../Views/Form';
import styles from './AddressRow.module.scss';
import cx from 'classnames';
import { ProductId } from '@ncs-frontend-monorepo/order';

interface AddressRow {
  templateId?: ProductId;
  address: FormFieldValues;
  noSpace?: boolean;
  className?: string;
  onChangeAddressClick?(): void;
}

const AddressRow: React.FC<AddressRow> = ({
  templateId,
  address,
  noSpace = false,
  className,
  onChangeAddressClick,
}) => {
  return (
    <div
      className={cx(styles.sublineWrapper, className, {
        [styles.noSpace]: noSpace,
      })}
    >
      <div className={styles.checkedAddress} data-cs-mask>
        <Text appearance="t4">
          {`${address?.zipCode}${address?.city && ` ${address?.city}`}, ${
            address?.street
          } ${address?.houseNumber}`}
        </Text>
      </div>
      {onChangeAddressClick && (
        <Spacer l={1}>
          <Link
            appearance="t5_2"
            e2e={`${templateId ? `${templateId}-` : ''}availability-re-check`}
            onClick={() => onChangeAddressClick?.()}
            url="#availability-check"
            className={styles.linkCentered}
            icon={<IconEdit color="blue" width={24} height={24} />}
          >
            Adresse ändern
          </Link>
        </Spacer>
      )}
    </div>
  );
};

export { AddressRow };
