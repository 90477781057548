import { Grid, IconEdit, Spacer, Text } from 'dss-ui-library';
import React from 'react';
import { AddressRow } from '../../../AvailabilityCheck/Elements/AddressRow';
import { getEnv } from '@ncs-frontend-monorepo/utils';
import { ProductInfoBox } from '../../../AvailabilityCheck/Elements/ProductInfoBox';
import dynamic from 'next/dynamic';
import { useAvailabilityContext } from '../../../AvailabilityCheck/Context';
import { HighlightedProposals } from '../../../AvailabilityCheck/Elements/HighlightedProposals';
import { Link } from '@ncs-frontend-monorepo/content-library';
import styles from './Summary.module.scss';
import { ProductId, Product } from '@ncs-frontend-monorepo/order';
import { Fallback, useAvailability } from '@ncs-frontend-monorepo/availability';

interface SummaryProps {
  availableProduct: Product;
  onResetAddress: () => void;
  isTVProduct: boolean;
  hasPreselect?: boolean;
  onResetPreselect: () => void;
}

const FibreOpticExpansion = dynamic(() =>
  import('../../../AvailabilityCheck/Elements/FibreOpticExpansion').then(
    ({ FibreOpticExpansion }) => FibreOpticExpansion,
  ),
);

export const Summary: React.FC<SummaryProps> = ({
  availableProduct,
  onResetAddress,
  isTVProduct,
  hasPreselect,
  onResetPreselect,
}) => {
  const { address, availability } = useAvailability();
  const { requiredPromotion, withoutRuntime } = useAvailabilityContext();
  const { objectInformation, isFTTHPlanned } = availability;
  const isFallback = availableProduct.fallback === Fallback.DOWNLOAD;
  const isPresale = !!requiredPromotion?.plannedAvailabilityDate;
  const isCableTVOption =
    isTVProduct &&
    ![
      ProductId.TV_CABLE,
      ProductId.TV_CABLE_EXISTING_CUSTOMER,
      ProductId.TV_CABLE_GWG_NEUSS,
    ].includes(availableProduct.id);
  const getHeadline = () => {
    switch (true) {
      case hasPreselect:
        return (
          <>
            Schön, dass du wieder da bist!
            <br />
            Ist deine Auswahl noch aktuell?
          </>
        );
      case isTVProduct:
        return <>Perfekt. NetTV über Kabel ist verfügbar!</>;
      case isFallback:
        return (
          <>
            Schade, dein Wunschtarif ist an dieser Adresse aktuell nicht
            verfügbar
          </>
        );
      case isPresale:
        return <>Dein Wunschtarif ist demnächst verfügbar</>;
      default:
        return <>Perfekt. Dein Wunschtarif ist verfügbar!</>;
    }
  };

  return (
    <>
      <Spacer b={1} r={4} block>
        <Text appearance="t2" color="blue">
          {getHeadline()}
        </Text>
      </Spacer>
      <AddressRow
        templateId={availableProduct.id}
        address={address}
        onChangeAddressClick={onResetAddress}
      />
      {isCableTVOption && (
        <Text appearance="t4" block>
          Du hast bereits einen Kabelanschluss von {getEnv().SITE}. Optimiere
          diesen mit unseren HD- und Zusatzpaketen. Unsere Empfehlung für dich:
        </Text>
      )}
      <Spacer t={2} block />
      {isFallback && !hasPreselect ? (
        <>
          {isFTTHPlanned && (
            <Grid.Row>
              <Grid.Column>
                <FibreOpticExpansion
                  buttonColor="blue"
                  displayStyle="nextto"
                  objectInformation={objectInformation}
                />
              </Grid.Column>
            </Grid.Row>
          )}
          <Grid.Row>
            <Grid.Column>
              <Text appearance="t2_2" color="blue">
                Unsere Alternative für dich
              </Text>
            </Grid.Column>
          </Grid.Row>
        </>
      ) : (
        <Grid.Row>
          <Grid.Column>
            <Text appearance="t2_2" color="blue">
              Dein ausgewählter Tarif
            </Text>
          </Grid.Column>
        </Grid.Row>
      )}
      {availableProduct?.displayProposals && (
        <HighlightedProposals
          displayProposals={availableProduct.displayProposals}
        />
      )}
      <Spacer y={3} block>
        <ProductInfoBox
          withoutRuntime={withoutRuntime}
          {...availableProduct}
          presaleDescription={
            requiredPromotion?.plannedAvailabilityDateDescription
          }
        />
      </Spacer>
      {hasPreselect && (
        <>
          <Spacer b={2} block>
            <Link
              appearance="t5_2"
              e2e="availability-re-select"
              onClick={onResetPreselect}
              url={null}
              className={styles.resetLink}
              icon={<IconEdit color="blue" width={24} height={24} />}
            >
              Auswahl ändern
            </Link>
          </Spacer>
          {isFTTHPlanned && (
            <FibreOpticExpansion
              buttonColor="blue"
              displayStyle="nextto"
              objectInformation={objectInformation}
            />
          )}
        </>
      )}
    </>
  );
};
