import { createContext, FC, ReactNode, useContext } from 'react';
import { ProductId } from '@ncs-frontend-monorepo/order';
import {
  LandlinePromotion,
  Portfolio,
} from '@ncs-frontend-monorepo/availability';

export enum AvailabilityTypes {
  Global = 'global',
  SmallGlobal = 'small-global',
  NetspeedPlan = 'netspeed-plan',
  TvPlan = 'tv-plan',
  TvInternetPlan = 'nettv-internet-plan',
  NetspeedContent = 'netspeed-content',
  TvContent = 'tv-content',
  Expansion = 'expansion',
  TariffAdvisor = 'tariff-advisor',
}

export interface AvailabilityContext {
  availabilityType: AvailabilityTypes;
  templateId?: ProductId;
  requestedDownload?: number;
  withoutRuntime?: boolean;
  requiredPromotion?: LandlinePromotion;
  manualCheck?: boolean;
  availablePortfolio?: Portfolio;
  kundenweltURL?: string;
  children?: ReactNode;
}

export const initialState: AvailabilityContext = {
  availabilityType: AvailabilityTypes.Global,
  availablePortfolio: 'FIBER',
};

const Context = createContext<AvailabilityContext | null>(null);
Context.displayName = 'AvailabilityContext';

const useAvailabilityContext: () => AvailabilityContext = () => {
  const contextState = useContext(Context);
  if (contextState === null) {
    throw new Error(
      'useAvailabilityContext must be used within a <AvailabilityProvider> tag',
    );
  }
  return contextState;
};

const AvailabilityProvider: FC<AvailabilityContext> = ({
  availabilityType,
  requestedDownload,
  requiredPromotion,
  templateId,
  withoutRuntime,
  manualCheck = false,
  availablePortfolio = 'FIBER',
  kundenweltURL,
  children,
}) => {
  const availabilityState = {
    ...initialState,
    availabilityType,
    requestedDownload,
    requiredPromotion,
    templateId,
    withoutRuntime,
    manualCheck,
    availablePortfolio,
    kundenweltURL,
  };

  return (
    <Context.Provider value={availabilityState}>{children}</Context.Provider>
  );
};

export { AvailabilityProvider, useAvailabilityContext };
