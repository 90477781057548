import { FC, ReactNode, useRef } from 'react';
import styles from './ProductDescriptionText.module.scss';
import { Text } from 'dss-ui-library';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import cx from 'classnames';
import { CSSTransitionProps } from 'react-transition-group/CSSTransition';

export interface ProductDescriptionTextProps {
  items: string[];
  animated?: boolean;
}

type ProductDescriptionTransitionProps = Partial<CSSTransitionProps> & {
  index: number;
  children: ReactNode;
};

const ProductDescriptionTransition: FC<ProductDescriptionTransitionProps> = ({
  index,
  children,
  ...props
}) => {
  const nodeRef = useRef(null);
  return (
    <CSSTransition
      {...props}
      nodeRef={nodeRef}
      timeout={400}
      classNames={{
        enter: styles.fadeEnter,
        enterActive: styles.fadeEnterActive,
        exit: styles.fadeExit,
        exitActive: styles.fadeExitActive,
      }}
    >
      <div ref={nodeRef} className={styles.item} data-index={index}>
        {children}
      </div>
    </CSSTransition>
  );
};

export const ProductDescriptionText: FC<ProductDescriptionTextProps> = ({
  items = [],
  animated = true,
}) => (
  <TransitionGroup
    data-e2e="ProductDescriptionText"
    className={cx(styles.productDescriptionText, {
      [styles.reserveSpace]: animated || items.length >= 2,
    })}
  >
    {items.map((desc, i, arr) => (
      <ProductDescriptionTransition key={i} index={i}>
        <Text element="h2" appearance="t6_2" color="black">
          {desc} {arr.length > i + 1 && '+'}
        </Text>
      </ProductDescriptionTransition>
    ))}
  </TransitionGroup>
);
