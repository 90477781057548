import { Button, Grid, IconChevron } from 'dss-ui-library';
import React from 'react';
import { Link } from '@ncs-frontend-monorepo/content-library';

interface ActionButtonsProps {
  handleNext(): void;
  handlePrev(): void;
  nextButtonText: string;
  e2e: string;
  withPrev?: boolean;
}

export const ActionButtons: React.FC<ActionButtonsProps> = ({
  handlePrev,
  handleNext,
  nextButtonText,
  e2e,
  withPrev = true,
}) => {
  return (
    <Grid fullWidth>
      <Grid.Row>
        {withPrev && (
          <Grid.Column hStartS s={6} vCenter>
            <Link
              e2e="form-back-button"
              url={null}
              onClick={handlePrev}
              icon={<IconChevron scale="small" rotation="270" small />}
            >
              Zurück
            </Link>
          </Grid.Column>
        )}
        <Grid.Column hEndS offsetStartS={withPrev ? 0 : 7} s={6} vCenter>
          <Button
            color="red"
            e2e={`go-to-${e2e}`}
            variant="primary"
            onClick={handleNext}
          >
            {nextButtonText}
          </Button>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};
