import React from 'react';
import { getProductInfoURL } from '../../../utils';
import styles from './ProductInfoDownload.module.scss';
import { DownloadLink } from '@ncs-frontend-monorepo/content-library';
import {
  CABLE_TV_COMBI_GROUPS,
  Product,
  ProductGroups,
  TV_GROUPS,
  TV_OPTIONS,
} from '@ncs-frontend-monorepo/order';

export const ProductInfoDownload: React.FC<Product> = ({
  group,
  id,
  tv,
  internet,
  groups,
}) => {
  const portfolioFiber = groups.includes(ProductGroups.FIBER)
    ? 'glasfaser-'
    : '';

  return TV_OPTIONS.includes(id) || TV_GROUPS.includes(group) ? (
    <></>
  ) : (
    <DownloadLink
      appearance="t5_2"
      url={`${getProductInfoURL()}${
        CABLE_TV_COMBI_GROUPS.includes(group)
          ? `${portfolioFiber}netspeedkabeltv${internet.download}.pdf`
          : tv
          ? `${portfolioFiber}netspeedtv${internet.download}.pdf`
          : `${portfolioFiber}netspeed${internet.download}.pdf`
      }`}
      e2e={`attachment-${id}`}
      className={styles.planLink}
      target="_blank"
    >
      Produktinformationsblatt
    </DownloadLink>
  );
};
