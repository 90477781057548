import {
  AvailabilityStatus,
  EventAvailabilityChangeAddress,
  EventAvailabilityCheck,
  EventAvailabilityClose,
  EventAvailabilityLinks,
  EventAvailabilityLogin,
  EventAvailabilityOpen,
  EventAvailabilityPresaleAlternative,
  EventAvailabilityPresaleSelect,
  EventAvailabilityResult,
  EventAvailabilityTooltip,
} from '../../interfaces/tracking';
import { pushDataLayer } from './index';
import {
  getMaxDownloads,
  Promotions,
} from '@ncs-frontend-monorepo/availability';

export const pushAvailability = () => {
  const eventOpen = (data: EventAvailabilityOpen) => {
    pushDataLayer(data);
  };

  const eventClose = (data: EventAvailabilityClose) => {
    pushDataLayer(data);
  };

  const eventTooltip = (data: EventAvailabilityTooltip) => {
    pushDataLayer(data);
  };

  const eventLogin = (data: EventAvailabilityLogin) => {
    pushDataLayer(data);
  };

  const eventCheck = (data: EventAvailabilityCheck) => {
    pushDataLayer(data);
  };

  const eventResult = (data: EventAvailabilityResult) => {
    pushDataLayer(data);
  };

  const eventChangeAddress = (data: EventAvailabilityChangeAddress) => {
    pushDataLayer(data);
  };

  const eventLinks = (data: EventAvailabilityLinks) => {
    pushDataLayer(data);
  };

  const eventPresaleSelect = (data: EventAvailabilityPresaleSelect) => {
    pushDataLayer(data);
  };

  const eventPresaleAlternative = (
    data: EventAvailabilityPresaleAlternative,
  ) => {
    pushDataLayer(data);
  };

  const getAvailabilityStatus = (
    promotions: Promotions,
    requestedDownload: number,
  ): AvailabilityStatus => {
    const { maxDownload } = getMaxDownloads({
      availablePromotions: promotions.availablePromotions,
      presalePromotion: promotions.presalePromotion,
    });

    //todo add FTTH
    switch (true) {
      case maxDownload > 0:
        return requestedDownload > maxDownload
          ? AvailabilityStatus.Alternative
          : AvailabilityStatus.Available;
      case !!promotions.presalePromotion:
        return maxDownload > 0
          ? AvailabilityStatus.AvailablePresale
          : AvailabilityStatus.PresaleOnly;
      default:
        return AvailabilityStatus.NotAvailable;
    }
  };

  return {
    getAvailabilityStatus,
    eventOpen,
    eventClose,
    eventTooltip,
    eventLogin,
    eventCheck,
    eventResult,
    eventChangeAddress,
    eventLinks,
    eventPresaleSelect,
    eventPresaleAlternative,
  };
};
