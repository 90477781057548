import cx from 'classnames';
import { Button, Grid, Separator, Spacer, Text } from 'dss-ui-library';
import { FC } from 'react';
import { createShortDescriptionByPlan } from '../../../utils';
import { ProductDescriptionText } from '../../PlanElements/ProductDescription';
import { ProductInfoDownload } from '../../PlanElements/ProductInfoDownload';
import { ProductName } from '../../PlanElements/ProductName';
import styles from './ProductInfoBox.module.scss';
import { Pricing, Product } from '@ncs-frontend-monorepo/order';
import { Media } from '@ncs-frontend-monorepo/utils';

interface ProductInfoBoxProps extends Product {
  withoutRuntime: boolean;
  presaleDescription?: string;
  isEmbedded?: boolean;
  onOrderClick?(): void;
}

const ProductInfoBox: FC<ProductInfoBoxProps> = ({
  onOrderClick,
  withoutRuntime,
  presaleDescription = '',
  isEmbedded = false,
  ...plan
}) => {
  const shortDescription = createShortDescriptionByPlan(plan);
  const isPresale = presaleDescription !== '';

  return (
    <Grid
      className={cx(styles.productInfoBox, { [styles.embedded]: isEmbedded })}
      fullWidth
    >
      <Grid.Row>
        <Grid.Column s={isPresale ? 5 : 7} m={isPresale ? 5 : 8}>
          <div className={styles.productWrapper}>
            <ProductName plan={plan} plainName />
            <ProductDescriptionText items={shortDescription} animated={false} />
            <Spacer b={2} block />
            <div className="fresnel-lessThan-s">
              <Spacer b={2} block>
                <Separator color="blue" height={4} />
              </Spacer>
            </div>
            <Media greaterThan="xs" className={styles.productDownload}>
              <ProductInfoDownload {...plan} />
            </Media>
          </div>
        </Grid.Column>
        {isPresale && (
          <Grid.Column
            xs={5}
            s={3}
            m={3}
            className={styles.preSale}
            hEndXs
            hCenterS
          >
            <div className={styles.label}>
              <Text appearance="t5" color="white">
                Verfügbar ab <strong>{presaleDescription}</strong>
              </Text>
            </div>
          </Grid.Column>
        )}
        <Grid.Column
          xs={isPresale ? 7 : 12}
          s={isPresale ? 4 : 5}
          m={4}
          hEndS
          className={styles.priceInfo}
        >
          <Pricing
            plan={plan}
            isLongTerm={!withoutRuntime}
            layout="vertical"
            priceTextInfo="mtl."
            wordBreakOnMobile={false}
          />
        </Grid.Column>
      </Grid.Row>
      <Media lessThan="s">
        <Grid.Row>
          <Grid.Column>
            <Spacer y={2}>
              <ProductInfoDownload {...plan} />
            </Spacer>
          </Grid.Column>
        </Grid.Row>
      </Media>
      <Grid.Row>
        {onOrderClick && (
          <Grid.Column hStartXs hEndS className={styles.orderButton}>
            <Button
              e2e={isPresale ? 'preorder' : 'order'}
              variant="primary"
              color="red"
              onClick={() => onOrderClick()}
            >
              {presaleDescription.length
                ? 'Jetzt vorbestellen'
                : 'Jetzt bestellen'}
            </Button>
          </Grid.Column>
        )}
      </Grid.Row>
    </Grid>
  );
};

export { ProductInfoBox };
