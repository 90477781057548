import { useAvailabilityContext } from '../AvailabilityCheck/Context';
import { useCallback, useEffect, useState } from 'react';
import { CheckoutViews, defaultCheckoutSteps } from './index';
import { validateChipsetId } from '../../utils/helpers';
import { ORDER_NETSPEED_URL, ORDER_TV_URL } from '../../constants/urls';
import { useRouter } from 'next/router';
import { AvailabilityStatus, DataLayerEvents } from '../../interfaces/tracking';
import { pushAvailability } from '../../utils/dataLayer/availability';
import {
  CheckoutStepId,
  CheckoutSteps,
  DeviceCategory,
  DeviceOwnership,
  useCheckoutSelectionStore,
  Product,
  useOrder,
  useReset,
  getProducts,
} from '@ncs-frontend-monorepo/order';
import {
  Fallback,
  NetTvType,
  useAvailability,
} from '@ncs-frontend-monorepo/availability';
import { useSalesAttribution } from '@ncs-frontend-monorepo/utils';

interface UseCheckoutProps {
  isTVProduct: boolean;
}
export const useCheckout = ({ isTVProduct }: UseCheckoutProps) => {
  const { updateBasket } = useOrder();
  const { resetBasket } = useReset();
  const { address, availability } = useAvailability();
  const {
    requiredPromotion,
    templateId: requestedTemplateId,
    withoutRuntime,
    requestedDownload,
    availabilityType,
    manualCheck,
  } = useAvailabilityContext();
  const {
    promotions: { fallback, tvPromotions },
    maxDownload,
    objectInformation,
  } = availability;
  const { getSalesAttribution } = useSalesAttribution();
  const { pubId } = getSalesAttribution();
  const router = useRouter();
  const { selection, reset, update } = useCheckoutSelectionStore();
  const { eventResult } = pushAvailability();
  const [availableProduct, setAvailableProduct] = useState<Product>();
  const [checkoutSteps, setCheckoutSteps] = useState<CheckoutSteps[]>();
  const [hasPreselect, setHasPreselect] = useState<boolean>();
  const [currentStep, setCurrentSep] = useState<number>();
  const [viewType, setViewType] = useState<CheckoutViews>();
  const [errorText, setErrorText] = useState('');

  const resetSelection = (showFirstStep = true) => {
    reset();
    if (showFirstStep) {
      setViewType(CheckoutViews.Steps);
      setCurrentSep(0);
    }
  };

  const goToStep = (step: number) => {
    setCurrentSep(step);
  };

  const handleNext = () => {
    if (viewType === CheckoutViews.Summary) {
      setCurrentSep(0);
      setViewType(CheckoutViews.Steps);
      return;
    }

    const { isValid, errorMessage } = isCurrentStepValid();

    if (!isValid) return setErrorText(errorMessage);
    setCurrentSep(currentStep + 1);
    setErrorText('');
  };

  const handlePrev = () => {
    setErrorText('');
    if (currentStep === 0) {
      setViewType(CheckoutViews.Summary);
    } else {
      goToStep(currentStep - 1);
    }
  };

  const getNetTVDevice = useCallback(() => {
    if (
      !checkoutSteps.some((steps) => steps.id === CheckoutStepId.NetTvDevice) ||
      !selection.netTvBox
    ) {
      return null;
    }

    const { category, ownership, id } = availableProduct.tv.devices.find(
      (device) => device.id === selection.netTvBox,
    );

    return {
      category,
      ownership,
      id,
    };
  }, [checkoutSteps, availableProduct, selection]);

  const getTVDevice = useCallback(() => {
    if (availableProduct.tv.devices.length === 0) {
      return null;
    }
    const { category } = availableProduct.tv.devices.find(
      (d) => d.id === selection?.tvDevice?.id,
    );
    return {
      ownership:
        category === DeviceCategory.BYOD
          ? DeviceOwnership.BUY
          : selection.tvDevice?.ownership,
      category,
      id: selection.tvDevice?.id,
      ...(category === DeviceCategory.BYOD && {
        chipsetId: selection.tvDevice?.chipsetId || '',
      }),
    };
  }, [availableProduct, selection]);

  const getIadDevice = useCallback(() => {
    const { category: iadCategory } = availableProduct.devices.find(
      (d) => d.id === selection.iadDevice.id,
    );
    return {
      ownership:
        iadCategory === DeviceCategory.BYOD
          ? DeviceOwnership.BUY
          : selection.iadDevice.ownership,
      category: iadCategory,
      id: selection.iadDevice.id,
    };
  }, [availableProduct, selection]);

  const getOntDevice = useCallback(() => {
    const ontDevice = availableProduct?.ontDevices?.find(
      (d) => d.id === selection.ontDevice.id,
    );

    if (!ontDevice) {
      return null;
    }

    const { category: ontCategory } = ontDevice;

    return {
      ownership:
        ontCategory === DeviceCategory.BYOD
          ? DeviceOwnership.BUY
          : selection.ontDevice.ownership,
      category: ontCategory,
      id: selection.ontDevice.id,
    };
  }, [availableProduct, selection]);

  const isCurrentStepValid = useCallback(() => {
    return hasValidSelection(checkoutSteps?.[currentStep || 0]?.id);
  }, [currentStep, checkoutSteps, selection]);

  const handleSubmit = useCallback(() => {
    if (![CheckoutViews.Checkout, CheckoutViews.Preselect].includes(viewType)) {
      const { isValid, errorMessage } = isCurrentStepValid();
      if (!isValid) return setErrorText(errorMessage);
      setErrorText('');
    }

    resetBasket();

    updateBasket({
      promotionId: isTVProduct ? null : availableProduct.promotionId,
      id: availableProduct.id,
      group: availableProduct.group,
      internet: isTVProduct ? null : availableProduct.internet,
      telephony: isTVProduct ? null : availableProduct?.telephony,
      fallback: isTVProduct
        ? Fallback.NONE
        : fallback || availableProduct.fallback,
      device: isTVProduct ? null : getIadDevice(),
      ontDevice: isTVProduct ? null : getOntDevice(),
      technology: isTVProduct ? null : availableProduct.technology,
      installationAddress: address,
      installationService: isTVProduct
        ? null
        : selection.installationService
        ? selection.installationService
        : null,
      isWithoutRuntime: isTVProduct ? false : withoutRuntime,
      plannedAvailabilityDate: requiredPromotion?.plannedAvailabilityDate,
      plannedAvailabilityDateDescription:
        requiredPromotion?.plannedAvailabilityDateDescription,
      tv: availableProduct.tv,
      tvDevice: isTVProduct ? getTVDevice() : getNetTVDevice(),
      ...(!isTVProduct && { pubId }),
    });
    router.push(isTVProduct ? ORDER_TV_URL : ORDER_NETSPEED_URL);
  }, [
    viewType,
    isCurrentStepValid,
    resetBasket,
    updateBasket,
    availableProduct,
    getTVDevice,
    getNetTVDevice,
    isTVProduct,
    router,
  ]);

  const hasValidSelection = useCallback(
    (
      stepId: CheckoutStepId,
    ): { isValid: boolean; errorMessage: string; triggerReset?: boolean } => {
      switch (stepId) {
        case CheckoutStepId.IadDevice:
          if (!selection.iadDevice) {
            return {
              isValid: false,
              errorMessage: 'Bitte wähle einen WLAN-Router aus.',
            };
          }

          if (selection.iadDevice.id === DeviceCategory.BYOD) {
            return {
              isValid: true,
              errorMessage: '',
            };
          }

          if (
            !availableProduct.devices.some(
              (iad) =>
                iad.id === selection?.iadDevice.id &&
                iad.ownership === selection.iadDevice?.ownership,
            )
          ) {
            return {
              isValid: false,
              errorMessage: 'Bitte wähle einen WLAN-Router aus.',
              triggerReset: true,
            };
          }
          break;
        // OntDevice
        case CheckoutStepId.OntDevice:
          if (!selection.ontDevice) {
            return {
              isValid: false,
              errorMessage: 'Bitte wähle eine Option aus.',
            };
          }
          if (selection.ontDevice.id === DeviceCategory.BYOD) {
            return {
              isValid: true,
              errorMessage: '',
            };
          }

          if (
            !availableProduct.ontDevices.some(
              (device) => device.id === selection?.ontDevice.id,
            )
          ) {
            return {
              isValid: false,
              errorMessage: 'Bitte wähle eine Option aus.',
            };
          }
          break;
        case CheckoutStepId.TvDevice:
          if (!selection.tvDevice) {
            return {
              isValid: false,
              errorMessage: 'Bitte wähle ein Empfangsgerät aus.',
            };
          }

          if (
            selection?.tvDevice.id === DeviceCategory.BYOD &&
            !validateChipsetId(selection.tvDevice.chipsetId)
          ) {
            return {
              isValid: false,
              errorMessage:
                'Die Chipset-ID muss 11-stellig sein und mit "00" beginnen.',
            };
          }
          break;
        case CheckoutStepId.NetTvDevice:
          if (selection.netTvBox === null) {
            return {
              isValid: false,
              errorMessage: 'Bitte wähle deine TV-Hardware aus.',
            };
          }
          break;
        case CheckoutStepId.Service:
          if (selection.installationService === null) {
            return {
              isValid: false,
              errorMessage: 'Bitte wähle einen Service aus.',
            };
          }
          break;
        default:
          return {
            isValid: false,
            errorMessage: 'Leider ist ein Fehler aufgetreten',
          };
      }
      return {
        isValid: true,
        errorMessage: '',
        triggerReset: false,
      };
    },
    [availableProduct, selection],
  );

  useEffect(() => {
    getProducts({
      templateId: requestedTemplateId,
      pubId,
      withoutRuntime,
      zipCode: address.zipCode,
      mandant: address?.mandant,
      ...(requiredPromotion?.maxDownload && {
        maxDownload: requiredPromotion.maxDownload,
      }),
      ...(requiredPromotion?.promotionId && {
        promotionId: requiredPromotion.promotionId,
      }),
    })
      .then((product) => {
        setAvailableProduct(product);
      })
      .catch((e) => {
        console.error(e);
      });
  }, [
    isTVProduct,
    requestedTemplateId,
    pubId,
    withoutRuntime,
    address.zipCode,
    address?.mandant,
    requiredPromotion?.maxDownload,
    requiredPromotion?.promotionId,
  ]);

  useEffect(() => {
    if (availableProduct) {
      const steps = defaultCheckoutSteps.reduce(
        (requiredStep: CheckoutSteps[], step) => {
          switch (step.id) {
            case CheckoutStepId.IadDevice:
              return availableProduct.devices.length > 0
                ? [...requiredStep, ...[step]]
                : requiredStep;
            case CheckoutStepId.OntDevice:
              return availableProduct.ontDevices?.length > 0
                ? [...requiredStep, ...[step]]
                : requiredStep;
            case CheckoutStepId.TvDevice:
              return [NetTvType.NET_TV_CABLE, NetTvType.NET_TV_FTTH].includes(
                availableProduct.tv?.type,
              ) && availableProduct.tv?.devices?.length > 0
                ? [...requiredStep, ...[step]]
                : requiredStep;
            case CheckoutStepId.NetTvDevice:
              return availableProduct.tv?.type === NetTvType.NET_TV_INTERNET &&
                availableProduct.tv?.devices?.length > 0
                ? [...requiredStep, ...[step]]
                : requiredStep;
            case CheckoutStepId.Service:
              return availableProduct?.installationServices.length > 0
                ? [...requiredStep, ...[step]]
                : requiredStep;
            default:
              return requiredStep;
          }
        },
        [],
      );

      setCheckoutSteps(steps);
    }
  }, [availableProduct]);

  useEffect(() => {
    const stepId = checkoutSteps?.[currentStep]?.id;
    if (!selection?.checkedSteps?.includes(stepId) && stepId) {
      update({
        checkedSteps: [...(selection?.checkedSteps || []), ...[stepId]],
      });
    }
  }, [currentStep, checkoutSteps, selection]);

  useEffect(() => {
    if (checkoutSteps && availableProduct) {
      let isPreSelected = true;
      let currentView = CheckoutViews.Summary;

      checkoutSteps.forEach((step) => {
        const { isValid, triggerReset } = hasValidSelection(step.id);
        const isChecked = selection?.checkedSteps?.includes(step.id);

        if (!isValid || !isChecked) {
          isPreSelected = false;
          triggerReset && reset();
          return;
        }
      });

      switch (true) {
        case checkoutSteps.length === 0:
          currentView = CheckoutViews.Checkout;
          break;
        case checkoutSteps.length === 1:
          currentView = CheckoutViews.SingleCheckout;
          break;
        case currentStep >= 0:
          currentView = CheckoutViews.Steps;
          break;
        case !currentStep && isPreSelected:
          currentView = CheckoutViews.Preselect;
          break;
        default:
          currentView = CheckoutViews.Summary;
          break;
      }

      setViewType(currentView);
      setHasPreselect(isPreSelected);
    }
  }, [checkoutSteps, availableProduct, selection]);

  useEffect(() => {
    if (availableProduct && hasPreselect !== undefined) {
      const availableStatus = isTVProduct
        ? AvailabilityStatus.Available
        : requiredPromotion.maxDownload >= requestedDownload
        ? AvailabilityStatus.Available
        : AvailabilityStatus.Alternative;

      const { technology, id } = availableProduct;

      eventResult({
        event: hasPreselect
          ? DataLayerEvents.ResultPreselect
          : DataLayerEvents.Result,
        status: availableStatus,
        checkSource: availabilityType,
        checkStatus: manualCheck ? 'nicht-geprueft' : 'bereits-geprueft',
        zipCode: address.zipCode,
        maxDownload,
        templateId: id,
        ...(!isTVProduct && {
          expansionStatus: objectInformation?.status || null,
          presaleAvailability: !!availability.planned,
          ...(availability.planned && {
            plannedAvailabilityDate:
              availability.planned.plannedAvailabilityDateDescription,
          }),
          technology,
          ...(tvPromotions && { tvPromotions }),
        }),
        wholebuy: objectInformation?.wholebuy || null,
      });
    }
  }, [
    availableProduct,
    hasPreselect,
    requiredPromotion,
    requestedDownload,
    availabilityType,
    manualCheck,
    address,
    maxDownload,
    isTVProduct,
    objectInformation,
    availability.planned,
  ]);

  return {
    availableProduct,
    checkoutSteps,
    currentStep,
    viewType,
    goToStep,
    resetSelection,
    errorText,
    handleNext,
    handlePrev,
    handleSubmit,
  };
};
