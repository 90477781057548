import { ChevronLink, Grid, Separator, Spacer, Text } from 'dss-ui-library';
import styles from './NetTvRedirect.module.scss';

interface IconTextSectionProps {
  title: string;
  text: string;
  icon: React.ReactElement;
  link: { title: string; url: string; e2e: string };
}

export const IconTextSection: React.FC<IconTextSectionProps> = ({
  icon,
  title,
  text,
  link,
}) => {
  return (
    <>
      <Text appearance="t5_2" color="blue">
        {title}
      </Text>
      <Spacer t={1} block />
      <Separator height={4} color="blue" />
      <Spacer t={2} block />
      <div className={styles.iconTextWrapper}>
        {icon}
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Text appearance="t5">{text}</Text>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Spacer t={2} block />
              <ChevronLink href={link.url} e2e={link.e2e}>
                {link.title}
              </ChevronLink>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    </>
  );
};
