import { LoadingSpinner } from 'dss-ui-library';
import dynamic from 'next/dynamic';
import { useCallback } from 'react';
import { AvailabilityProps } from '../components/AvailabilityCheck';
import { AvailabilityTypes } from '../components/AvailabilityCheck/Context';
import { useModalContext } from '../components/ModalContext';
import { Product } from '@ncs-frontend-monorepo/order';
import { Portfolio } from '@ncs-frontend-monorepo/availability';

const Availability = dynamic(
  () =>
    import('../components/AvailabilityCheck/Availability').then(
      ({ Availability }) => Availability,
    ),
  {
    ssr: false,
    loading: () => <LoadingSpinner theme="blue" />,
  },
);

export interface OpenModalProps {
  plan?: Product;
  checkoutActionCode?: string;
  isLongTerm?: boolean;
  portfolio?: Portfolio;
  desiredEntrypoint?: AvailabilityProps['desiredEntrypoint'];
  availabilityType: AvailabilityTypes;
  e2e?: string;
  kundenweltURL?: string;
}

export const useAvailabilityModal = () => {
  const { handleModal } = useModalContext();

  const openModal = useCallback(
    ({
      plan,
      checkoutActionCode,
      isLongTerm = true,
      portfolio,
      desiredEntrypoint,
      availabilityType,
      e2e,
      kundenweltURL,
    }: OpenModalProps) => {
      const availabilityProps = {
        availabilityType,
        checkoutActionCode,
        withoutRuntime: !isLongTerm,
        ...(plan && {
          templateId: plan.id,
          templateGroup: plan.group,
          requestedPlanName: plan.name,
          ...(plan?.internet?.download && {
            requestedDownload: plan.internet.download,
          }),
          groups: plan.groups,
        }),
        ...(desiredEntrypoint && { desiredEntrypoint }),
        portfolio,
        kundenweltURL,
      };

      handleModal({
        content: <Availability {...availabilityProps} />,
        e2e: e2e || `${plan?.id}-availability-check`,
      });
    },
    [],
  );

  return {
    openModal,
  };
};
