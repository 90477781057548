import React, { ReactNode } from 'react';
import {
  isCombiPlan,
  isYoungPlan,
  Product,
  ProductGroups,
} from '@ncs-frontend-monorepo/order';

export const getFormattedPlanName = (product: Product): string | ReactNode => {
  // check if fiber plan
  if (product.groups.includes(ProductGroups.FIBER)) {
    if (isYoungPlan(product.groups)) {
      return isCombiPlan(product.groups) ? (
        <>
          Glasfaser
          <br />
          Kombi Young
          <br />
          {product?.internet?.download}
        </>
      ) : (
        <>
          Glasfaser NetSpeed
          <br />
          Young {product?.internet?.download}
        </>
      );
    } else {
      return isCombiPlan(product.groups) ? (
        <>
          Glasfaser
          <br />
          Kombi {product?.internet?.download}
        </>
      ) : (
        <>
          Glasfaser
          <br />
          NetSpeed {product?.internet?.download}
        </>
      );
    }
  }

  return product.name;
};
