import React from 'react';
import { Text, IconFiberProduct, Spacer } from 'dss-ui-library';
import { getFormattedPlanName } from '../../../utils/getFormattedPlanName';
import styles from './ProductName.module.scss';
import { isFiberPlan, Product } from '@ncs-frontend-monorepo/order';

interface ProductNameProps {
  plan: Product;
  e2e?: string;
  plainName?: boolean;
}
export const ProductName: React.FC<ProductNameProps> = ({
  plan,
  e2e,
  plainName = false,
}) => {
  return (
    <div
      data-e2e={`product-name${e2e ? `-${e2e}` : ''}`}
      className={styles.productNameWrapper}
    >
      {isFiberPlan(plan.technology) && (
        <Spacer r={1} block>
          <IconFiberProduct
            color="red"
            width={plainName ? 26 : 40}
            height={plainName ? 32 : 48}
          />
        </Spacer>
      )}
      <Text appearance="t2" color="black" block e2e="planColumn-name">
        {plainName ? plan.name : getFormattedPlanName(plan)}
      </Text>
    </div>
  );
};
